import React from "react";
import NextHead from "next/head";

export const SiteHead: React.FC = () => {
	return (
		<NextHead>
			<meta name="facebook-domain-verification" content="0d8oe5s5up43tv0dtqoapunwi90cjx" />
		</NextHead>
	);
};
