import React from "react";
import { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { SiteHead } from "~/components/SiteHead";

import { theme } from "../themes";
import { bootAPI } from "~/resources/api";
import strings from "~/resources/strings";

const App = (props: AppProps) => {
	const { Component, pageProps } = props;

	return (
		<ChakraProvider theme={theme}>
			<SiteHead />
			<Component {...pageProps} />
		</ChakraProvider>
	);
};

bootAPI(strings);

export default App;
