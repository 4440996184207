import { extendTheme } from "@chakra-ui/react";
import { spacing } from "./spacing";
import { fontSizes } from "./fontSizes";

export const theme = extendTheme(
	{
		styles: {
			global: {
				"html, body": {
					fontFamily: "Manrope, sans-serif",
				},
			},
		},
		fontSizes,
		spacing,
	},
);
