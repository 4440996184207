import * as API from "@startapp/loc-admin-api";

import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.meuloc.com/admin";
const stagingUrl = "https://api.meuloc.com/admin-staging";

const bootAPI = (strings: LocalizedStringsMethods) => {
	const getAPIURL = () => {
		switch (process.env.APP_ENV) {
			case "production":
				return productionUrl;
			case "staging":
			default:
				return stagingUrl;
		}
	};

	API.setStrings(strings);
	API.setUrl(getAPIURL());
};

export default API;

export { bootAPI };
