/* eslint-disable max-len */
import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBr: {
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			invalidArgument: "Argumento inválido",
			stillLoading: "Ainda Carregando, aguarde.",
		},
		home: {
			description: "Looks infinitos em um clique ✨",
			links: {
				link1: "https://franquia.meuloc.com/",
				link2: "https://download.meuloc.com/",
				link3: "https://api.whatsapp.com/send/?phone=5511995727999&text&type=phone_number&app_absent=0",
				link4: "https://api.whatsapp.com/send/?phone=5571997017074&text&type=phone_number&app_absent=0",
			},
			textButtons: {
				text1: "Leve o LOC para sua cidade",
				text2: "Baixe o aplicativo",
				text3: "Posso te ajudar? Fale com o suporte",
				text4: "Procurando algo? Fale com o Concierge",
			},
		},
	},
},
);

export default strings;
